import SelectWorkspace from "../../components/BoardSettings/SelectWorkspace";
import {useAppSelector} from "../../store/hooks";
import "./PageStyles.css";

const ChangeSuperBoardPage = () => {
    const lightMode = useAppSelector((state) => state.navigation.lightMode);
    return (
        <>
            <div className={`${lightMode} container-change-workspace`}>
                <SelectWorkspace/>
            </div>
        </>
    );
};
export default ChangeSuperBoardPage;

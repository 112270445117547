import {Fetch, FetchInput, Method} from "../../Fetch";

export interface AcceptInviteToBoardRequest {
    userEmail: string;
    boardId: string;
    password?: string;
    name?: string;
    signal?: AbortSignal;
}

export interface AcceptInviteToBoardResponse {
    error: string;
    response: any;
}

export async function AcceptInviteToBoard(
    request: AcceptInviteToBoardRequest
): Promise<AcceptInviteToBoardResponse> {
    const AcceptInviteURL = "/public/board/accept_invite";
    const requestBody = JSON.stringify({
        userEmail: request.userEmail,
        boardId: request.boardId,
        password: request.password,
        name: request.name,
    });
    try {
        const input: FetchInput = {
            method: Method.Post,
            url: AcceptInviteURL,
            signal: request.signal,
            body: requestBody,
        };
        const result = await Fetch(input);

        if (result.isOk) {
            const response = await result;

            if (response) {
                return {
                    error: "",
                    response: response.result.id,
                };
            }

            return {
                error: "Server side error",
                response: "",
            };
        } else {
            return {
                error: result.error?.message ?? "",
                response: "",
            };
        }
    } catch (err) {
        console.error(err);
        return {
            error: "error",
            response: "",
        };
    }
}

import {useNavigate, useSearchParams} from "react-router-dom";
import {AcceptInviteToBoard, AcceptInviteToBoardRequest,} from "../../utils/backend/Invites/AcceptInviteToBoard";
import {useEffect, useState} from "react";
import {useAppSelector} from "../../store/hooks";
import {AcceptInviteToProject, AcceptInviteToProjectRequest} from "../../utils/backend/Invites/AcceptInviteToProject";
import NewUserForm from "./NewUserForm";

const AcceptInvitation = () => {
    const [searchParams] = useSearchParams();
    const email = searchParams.get("email");
    const boardId = searchParams.get("boardId");
    const projectId = searchParams.get("projectId");
    const newUser = searchParams.get("newUser");
    const isNewUser = newUser === "true";
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const lightMode = useAppSelector((state) => state.navigation.lightMode);

    const [error, setError] = useState<string | undefined>();

    useEffect(() => {
        const checkUserToken = () => {
            const userToken = localStorage.getItem("token");
            if (!userToken || userToken === "undefined") {
                setIsLoggedIn(false);
            } else {
                setIsLoggedIn(true);
            }
        };
        checkUserToken();
    }, [isLoggedIn, navigate]);

    if (!email) {
        navigate("/");
        return null;
    }

    const controller = new AbortController();
    const signal = controller.signal;

    const handleAcceptInviteToBoard = async (boardId: string) => {
        const req: AcceptInviteToBoardRequest = {
            userEmail: email,
            boardId: boardId,
            signal,
        };
        try {
            const result = await AcceptInviteToBoard(req);
            if (result.error.length > 0) {
                setError(
                    "Something unexpected happened, try to reload page or contact us."
                );
                console.error(result.error);
                return;
            }
        } catch (e) {
            setError(
                "Something unexpected happened, try to reload page or contact us."
            );
            console.error(e);
        }
    };

    const handleAcceptInviteToProject = async (projectId: string) => {
        const req: AcceptInviteToProjectRequest = {
            userEmail: email,
            projectId: projectId,
            signal,
        };
        try {
            const result = await AcceptInviteToProject(req);
            if (result.error.length > 0) {
                setError(
                    "Something unexpected happened, try to reload page or contact us."
                );
                console.error(result.error);
                return;
            }
        } catch (e) {
            setError(
                "Something unexpected happened, try to reload page or contact us."
            );
            console.error(e);
        }

    };

    if (!boardId && !projectId) {
        navigate("/");
        return null;
    }

    if (isNewUser) {
        if (boardId) {
            return <NewUserForm
                boardId={boardId}
                type={"board"}
                lightMode={lightMode}
                onSuccess={() => {
                    navigate(`/verification?email=${encodeURIComponent(email)}&invitationAccepted=true`)
                }}
            />
        }
        if (projectId) {
            return <NewUserForm
                superBoardId={projectId}
                type={"project"}
                lightMode={lightMode}
                onSuccess={() => {
                    navigate(`/verification?email=${encodeURIComponent(email)}&invitationAccepted=true`)
                }}
            />
        }
    }

    if (boardId) {
        handleAcceptInviteToBoard(boardId).then(() => {
            if (isLoggedIn) {
                navigate("/Navigation/Screens?invitationAccepted=true");
            } else {
                navigate(`/SignIn/?email=${email}&active=login&invitationAccepted=true`)
            }
        }).catch(err => {
            setError(
                "Something unexpected happened, try to reload page or contact us."
            );
            console.error(err);
        });

    } else if (projectId) {
        handleAcceptInviteToProject(projectId).then(() => {
            if (isLoggedIn) {
                navigate("/Navigation/Screens?invitationAccepted=true");
            } else {
                navigate(`/SignIn/?email=${email}&active=login&invitationAccepted=true`)
            }
        }).catch(err => {
            setError(
                "Something unexpected happened, try to reload page or contact us."
            );
            console.error(err);
        });
    }

    return null;
};
export default AcceptInvitation;

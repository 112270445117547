import React from "react";
import {useAppSelector} from "../../store/hooks";

import "./../UpgradePlanModal/UpgradePlanStyles.css";

import closeIcon from "../../icons/closeWhite.svg";
import closeIconDark from "../../icons/close.svg";
import check from "../../icons/green-check.svg";

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const InvitationModalAccepted: React.FC<ModalProps> = ({isOpen, onClose}) => {
    const lightMode = useAppSelector((state) => state.navigation.lightMode);

    const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement>) => {
        if (event.target === event.currentTarget) {
            onClose();
        }
    };

    if (!isOpen) return null;


    return (
        <div className={`${lightMode} modal-overlay`} onClick={handleOverlayClick}>
            <div className={`${lightMode} modal upgrade-plan thank-you`}>
                <div className={`${lightMode} modal-content upgrade-plan thank-you`}>
                    <div className={`${lightMode} upgrade-plan-header thank-you`}>
                        <div className="check-icon-container">
                            <img src={check} alt={"check"}/>
                        </div>
                        <div className={`${lightMode} upgrade-plan-header-right`}>
                            <img
                                src={lightMode === "light" ? closeIconDark : closeIcon}
                                style={{width: "18px", height: "18px"}}
                                onClick={onClose}
                                alt="CloseIcon"
                            />
                        </div>
                    </div>
                    <div className={`${lightMode} upgrade-plan-content thank-you`}>
                        <p className={`${lightMode} thank-you-header`}>
                            Invitation successfully accepted
                        </p>
                    </div>
                    <div>
                        <button className={`${lightMode} thank-you-btn`} onClick={onClose}>
                            Continue
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InvitationModalAccepted;

import React, {useEffect, useState} from "react";
import Logo from "../../icons/favicon.png";
import "./Verification.css";
import {useNavigate, useSearchParams} from "react-router-dom";
import {FetchVerification, VerificationRequest,} from "../../utils/backend/SignIn SignUp/FetchVerification";
import {
    FetchResendVerificationEmail,
    ResendVerificationEmailRequest,
} from "../../utils/backend/SignIn SignUp/ResendVerificationEmail";
import {SubmitHandler, useForm} from "react-hook-form";
import {isValidEmail} from "../../utils/helpers";
import {FetchSignIn} from "../../utils/backend/SignIn SignUp/FetchSignIn";
import {FetchAllTagTypesWithTags} from "../../utils/backend/TagType/AllWithTags";
import {setAllTagTypesWithTags} from "../../store/slice/tagSlice";
import {setProjects, setWorkspaces} from "../../store/slice/workspacesSlice";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {GetUser} from "../../utils/backend/Profile/GetUser";
import ShowIcon from "../../icons/Show.svg";
import HideIcon from "../../icons/Hide.svg";
import {setUser} from "../../store/slice/authSlice";
import posthog from "posthog-js";

const Verification = () => {
    const dispatch = useAppDispatch();
    const lightMode = useAppSelector((state) => state.navigation.lightMode);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [isPasswordVisible, setisPasswordVisible] = useState({
        password: false,
        confirmPassword: false,
    });
    const pwd = localStorage.getItem("pwd");

    const isBeta = searchParams.get("isBeta");

    const {
        handleSubmit,
        register,
        formState: {errors, isSubmitting},
        setError,
        getValues,
    } = useForm<VerificationRequest>({
        defaultValues: {
            email: searchParams.get("email") ?? "",
            token: searchParams.get("code") ?? "",
        },
    });

    useEffect(() => {
        const currentUserToken = localStorage.getItem("token");
        if (!currentUserToken) {
            return;
        }
        const handleGetUser = async function () {
            const result = await GetUser();
            if (result.user) {
                dispatch(setUser(result.user!));
            }

            if (result.error.length > 0) {
                setError("root", {
                    message:
                        "Something unexpected happened, try to reload page or contact us.",
                });
                console.error(result.error);
                return;
            }
        };

        handleGetUser().catch((e) => {
            setError("root", {
                message:
                    "Something unexpected happened, try to reload page or contact us.",
            });
            console.error(e);
        });
    }, [navigate, setError]);

    const resendEmail = () => {
        const emailData = getValues().email;
        if (!emailData) {
            setError("email", {
                message: "Required",
            });
            return;
        }

        if (!isValidEmail(emailData)) {
            setError("email", {
                type: "validate",
            });
            return;
        }

        const req: ResendVerificationEmailRequest = {
            email: getValues().email,
        };
        FetchResendVerificationEmail(req)
            .then((res) => {
                if (res.isOk) return;
                if (res.error) {
                    switch (res.error) {
                        case "Conflict": {
                            setError("root", {
                                message: "User already verified",
                            });
                            break;
                        }
                        case "Not Found": {
                            setError("root", {
                                message: "User with given email doesn't exist",
                            });
                            break;
                        }
                        default: {
                            setError("root", {
                                message: "Unexpected error, try again or contact us",
                            });
                        }
                    }
                    return;
                }

                setError("root", {
                    message: "Unexpected error, try again or contact us",
                });
            })
            .catch((err) => {
                setError("root", {
                    message: "Unexpected error, try again or contact us",
                });
                console.error(err);
            });
    };

    const onSubmit: SubmitHandler<VerificationRequest> = async (
        data: VerificationRequest
    ) => {
        const handleGetAllDataForDataInitialization =
            async (): Promise<boolean> => {
                try {
                    const result = await FetchAllTagTypesWithTags();
                    if (result.error) {
                        return false;
                    }

                    dispatch(setAllTagTypesWithTags(result.response));
                    dispatch(setProjects([]));
                    dispatch(setWorkspaces([]));

                    return true;
                } catch (e) {
                    setError("root", {
                        message: "Unable to sign you in, please try again or contact us.",
                    });
                    console.error(e);
                    return false;
                }
            };

        const signIn = async (email: string, password: string) => {
            const response = await FetchSignIn({
                Email: email,
                Password: pwd!,
            });

            if (response.token) {
                localStorage.setItem("token", JSON.stringify(response));
                if (await handleGetAllDataForDataInitialization()) {
                    localStorage.removeItem("pwd");
                    navigate("/SetupTeam");
                } else {
                    localStorage.removeItem("token");
                    localStorage.removeItem("projectId");
                    localStorage.removeItem("boardId");
                    localStorage.removeItem("allTagTypesWithTags");
                }

                throw new Error("error");
            }

            if (response.error) {
                console.error(response.error);
                throw new Error("error");
            }
        };

        try {
            const result = await FetchVerification(data);
            if (result.isOk) {
                posthog.capture("userProfileVerified", {
                    title: data.email,
                });
                await signIn(data.email, data.password ?? "").catch(() => {
                    navigate(`/SignIn?active=login&email=${data.email}`);
                });
                return;
            }

            if (result.error === "Already verified") {
                navigate(`/SignIn?active=login&email=${data.email}`);
                return;
            }

            setError("root", {
                message: "Email or verification code not valid",
            });
        } catch (err) {
            setError("root", {
                message: "Unexpected error, try again or contact us",
            });
            console.error(err);
        }
    };

    return (
        <div className={`${lightMode} verification-page`}>
            <img src={Logo} className={`${lightMode} uxmaps-logo sign-page`}/>
            <div className={`${lightMode} verification`}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={`${lightMode} top`}>
                        <h1 className={`${lightMode} verification-title`}>
                            Verify your email
                        </h1>
                        <h2 className={`${lightMode} wb`}>
                            Please check your inbox for a verification code to confirm your
                            email.
                        </h2>
                    </div>

                    <label htmlFor="verification-code">Enter your email</label>
                    <input
                        id="email"
                        type="email"
                        placeholder="Your email"
                        className={`${lightMode} sign-in-input`}
                        disabled={!!searchParams.get("email")}
                        style={{
                            backgroundColor: "#f2f4f7",
                        }}
                        {...register("email", {required: true, validate: isValidEmail})}
                    />
                    {errors.email && (
                        <div
                            style={{
                                color: "red",
                                fontSize: "12px",
                                marginTop: "5px",
                                fontWeight: 500,
                            }}
                        >
                            {errors.email.message && errors.email.message?.length > 0
                                ? errors.email.message
                                : errors.email && errors.email.type === "required"
                                    ? "Required"
                                    : errors.email && errors.email.type === "validate"
                                        ? "Not valid email"
                                        : null}
                        </div>
                    )}
                    {isBeta === "true" && (
                        <>
                            <label htmlFor="verification-code">Setup your password</label>
                            <div>
                                <input
                                    className={`${lightMode} sign-in-input`}
                                    id="password"
                                    type={!isPasswordVisible.password ? "password" : "text"}
                                    placeholder="&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;"
                                    {...register("password", {
                                        required: true,
                                        validate: (value: any) => {
                                            if (value.length < 8) {
                                                return `Must be at least ${8} characters long.`;
                                            }
                                        },
                                    })}
                                />
                                <img
                                    src={isPasswordVisible.password ? HideIcon : ShowIcon}
                                    onClick={() =>
                                        setisPasswordVisible({
                                            password: !isPasswordVisible.password,
                                            confirmPassword: isPasswordVisible.confirmPassword,
                                        })
                                    }
                                    alt="Show/Hide"
                                    className={`${lightMode} show-hide-image-verification`}
                                />
                            </div>
                            {errors.password && (
                                <div
                                    style={{
                                        color: "red",
                                        fontSize: "12px",
                                        marginTop: "5px",
                                        fontWeight: 500,
                                    }}
                                >
                                    {errors.password.message &&
                                    errors.password.message?.length > 0
                                        ? errors.password.message
                                        : errors.password && errors.password.type === "required"
                                            ? "Required"
                                            : errors.password && errors.password.type === "validate"
                                                ? "Not valid password"
                                                : null}
                                </div>
                            )}
                        </>
                    )}

                    <label htmlFor="verification-code" style={{marginTop: "20px"}}>
                        Enter your 6-digit code below
                    </label>
                    <div>
                        <input
                            className={`${lightMode} sign-in-input`}
                            id="verification-code"
                            type={!isPasswordVisible.confirmPassword ? "password" : "text"}
                            placeholder="&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;"
                            style={{marginBottom: "20px"}}
                            {...register("token", {
                                required: true,
                                validate: (value: any) => {
                                    if (value.length !== 6) {
                                        return `Must be exactly ${6} digits long.`;
                                    }
                                },
                            })}
                        />
                        <img
                            src={isPasswordVisible.confirmPassword ? HideIcon : ShowIcon}
                            onClick={() =>
                                setisPasswordVisible({
                                    password: isPasswordVisible.password,
                                    confirmPassword: !isPasswordVisible.confirmPassword,
                                })
                            }
                            alt="Show/Hide"
                            className={`${lightMode} show-hide-image-verification`}
                        />
                    </div>
                    {errors.token && (
                        <div
                            style={{
                                color: "red",
                                fontSize: "12px",
                                marginTop: "5px",
                                fontWeight: 500,
                            }}
                        >
                            {errors.token.message && errors.token.message?.length > 0
                                ? errors.token.message
                                : errors.token && errors.token.type === "required"
                                    ? "Required"
                                    : errors.token && errors.token.type === "validate"
                                        ? "Not valid token"
                                        : null}
                        </div>
                    )}
                    <button
                        className={`${lightMode} sign-in-btn`}
                        type="submit"
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? "Loading..." : "Verify email"}
                    </button>
                    {errors.root && (
                        <div
                            style={{
                                color: "red",
                                fontSize: "12px",
                                marginTop: "5px",
                                fontWeight: 500,
                            }}
                        >
                            {errors.root.message}
                        </div>
                    )}
                </form>
                <div className={`${lightMode} resend-email`}>
                    <button
                        className={`${lightMode} resend-email-btn`}
                        onClick={resendEmail}
                    >
                        Resend verification email
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Verification;

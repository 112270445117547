import React, {useCallback} from 'react';
import "./styles.css";
import {useAppSelector} from "../../store/hooks";
import ErrorPage from "../../components/ErrorPage/ErrorPage";
import {SubmitHandler, useForm} from "react-hook-form";
import {UseRedemptionCode} from "../../utils/backend/RedemptionCodes/UseRedemptionCode";
import {useNavigate} from "react-router-dom";

interface formData {
    code: string;
}

export function RocketHubRedemptionPage() {
    const lightMode = useAppSelector((state) => state.navigation.lightMode);
    const selectedProject = useAppSelector(
        (state) => state.boards.selectedProject
    );

    const navigate = useNavigate();

    const {
        handleSubmit,
        register,
        formState: {errors, isSubmitting},
        setError,
    } = useForm<formData>();

    const onSubmit: SubmitHandler<formData> = useCallback(async (data: formData) => {
        if (!selectedProject) {
            return;
        }
        try {
            const codeUsed = await UseRedemptionCode({
                code: data.code,
                projectId: selectedProject.id
            });

            if (codeUsed) {
                navigate("/Navigation/Screens?success=true&type=redemption");
                return;
            } else {
                setError("root", {
                    message: "Invalid code provided"
                });
            }
        } catch (e) {
            setError("root", {
                message: "Something went wrong, please try again or contact our support."
            })
        }
    }, [navigate, selectedProject, setError]);

    if (!selectedProject) {
        return <ErrorPage/>
    }

    return <div className={"redemption-page"}>
        <form className={`${lightMode} redemption-container`} onSubmit={handleSubmit(onSubmit)}>
            <div className={`${lightMode} text-center redeem-title`}>Redeem your RocketHub Deal</div>
            <input
                type="text"
                id="superboard.name"
                className={`${lightMode} redemption-code-input`}
                {...register("code", {required: true})}
                placeholder="Enter Redemption Code"
            />
            {errors.code && (
                <div
                    className={"error-message"}
                >
                    Required
                </div>
            )}
            <button
                type={"submit"}
                className={`${lightMode} redeem-button`}>
                {isSubmitting ? "Loading..." : "Redeem"}
            </button>
            {errors.root && (
                <div
                    className={"error-message"}
                >
                    {errors.root.message}
                </div>
            )}
        </form>
    </div>
}
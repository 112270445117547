import {Fetch, Method} from "../../Fetch";

export interface UseRedemptionCodeRequest {
    code: string;
    projectId: string;
    signal?: AbortSignal;
}

export async function UseRedemptionCode({code, projectId, signal}: UseRedemptionCodeRequest): Promise<boolean> {
    const url = `/me/redemption_code/use`;
    const body = {
        code,
        projectId,
    };

    try {
        const response = await Fetch({
            body: JSON.stringify(body),
            method: Method.Post,
            url: url,
            signal,
        });

        return response.isOk;
    } catch (err) {
        console.error(err);
        return false;
    }
}
import "./CompanyItem.css";
import {useEffect, useRef, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {StyledScreenImage} from "../../common/ProductPage/ProductPage.styles";
import useContextMenu from "./ContextMenuHook";
import {ContextMenuCompanyItem} from "./StyledContextMenu";
import Trash from "../../../icons/trash-2.svg";
import {asyncNotification} from "../../../store/slice/notificationSlice";
import {
    DeleteFromCollection,
    DeleteFromCollectionRequest,
} from "../../../utils/backend/Collection/DeleteFromCollection";
import OpenImg from "../../../icons/arrow-up-right.svg";
import OpenImgDark from "../../../icons/arrow-up-right-dark.svg";
import copy from "../../../icons/copy.svg";
import copyDark from "../../../icons/copyDark.svg";
import {Tag} from "../../../utils/types/tag";
import {Screen} from "../../../utils/types/screen";
import LockImg from "../../../icons/lock.svg";
import LockImgDark from "../../../icons/lock-dark.svg";
import UpgradePlanModal from "../../UpgradePlanModal/UpgradePlanModal";
import {useIsMobile} from "../../../utils/hooks/useIsMobile";

interface CompanyItemProps {
    name?: string;
    screen?: Screen;
    id?: string;
    image?: any;
    key?: string;
    companyName?: string;
    companyIcon?: string;
    collectionList?: Array<string>;
    collectionId?: string;
    onFlows?: boolean;
    selectingMode?: boolean;
    removeScreen?: (id: string) => void;
    collectionChangedFunc?: (
        screenId: string,
        collectionId: string,
        type: string
    ) => void;
    handleClick?: () => void;
    company?: Tag | null | undefined;
    selectingScreens?: any;
    hideCollDropdown?: any;
    displayCheckbox?: any;
    locked?: boolean | undefined;
}

const CompanyItem = (
    {
        name,
        id,
        collectionId,
        collectionList,
        handleClick,
        image,
        onFlows,
        removeScreen,
        company,
        screen,
    }: CompanyItemProps) => {
    const {clicked, setClicked, points, setPoints} = useContextMenu();
    const areFiltersOpen = useAppSelector(
        (state) => state.filters.isFilterOpened
    );
    const [innerWidth, setInnerWidth] = useState<number>(
        document.documentElement.clientWidth
    );

    const ref = useRef<HTMLDivElement>(null);

    const dispatch = useAppDispatch();

    useEffect(() => {
        const handleResize = () => {
            setInnerWidth(document.documentElement.clientWidth);
        };
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    const deleteScreenFromCollection = async (
        screens: Array<string>,
        collectionId: string,
        screenId: string
    ) => {
        try {
            const controller = new AbortController();
            const signal = controller.signal;

            const req: DeleteFromCollectionRequest = {
                signal: signal,
                collectionId: collectionId,
                contentIdList: [screenId],
            };

            if (screens.length === 0 || !collectionId) return;
            const result = await DeleteFromCollection(req);
            if (result && removeScreen) {
                removeScreen(screenId);
                dispatch(
                    asyncNotification({
                        notification: "Screen removed",
                        isActive: true,
                        isErrNotification: false,
                    })
                );
            }
        } catch (error) {
            dispatch(asyncNotification({
                notification: "Failed to remove screen",
                isActive: true,
                isErrNotification: true,
            }));
        }
    };

    const [isUpgradePlanModalOpened, setIsUpgradePlanModalOpened] =
        useState<boolean>(false);

    const lightMode = useAppSelector((state) => state.navigation.lightMode);

    const locked = screen?.name?.includes("Locked Screen");
    const {isMobile} = useIsMobile();

    return (
        <div
            ref={ref}
            onMouseLeave={() => {
                if (window.location.pathname.includes("/Collections"))
                    setClicked(false);
            }}
            className={`${lightMode}
				${
                onFlows === false
                    ? `${lightMode} item-${id} item company flows`
                    : areFiltersOpen
                        ? `${lightMode} item-${id} item company with-filters`
                        : `${lightMode} item-${id} item company`
            }
			`}
            onContextMenu={(e) => {
                if (window.location.pathname.includes("/Collections")) {
                    e.preventDefault();
                    if (ref.current) {
                        const rect = ref.current.getBoundingClientRect();
                        setClicked(true);
                        setPoints({
                            x: e.clientX - rect.left,
                            y: e.clientY - rect.top,
                        });
                    }
                }
            }}
            id={id}
            onClick={clicked || locked ? (e) => e.preventDefault() : handleClick}
        >
            <UpgradePlanModal
                onClose={() => setIsUpgradePlanModalOpened(false)}
                isOpen={isUpgradePlanModalOpened}
                type="limit"
            />
            {clicked && window.location.pathname.includes("/Collections") && (
                <ContextMenuCompanyItem
                    top={points.y}
                    left={points.x}
                    theme={lightMode}
                >
                    <div
                        onClick={() => {
                            if (!locked && handleClick) handleClick();
                        }}
                        className={`${lightMode} dropdown-content-div collections-context-menu `}
                    >
                        <img src={lightMode === "light" ? OpenImg : OpenImgDark} alt=""/>
                        Open
                    </div>
                    <div
                        onClick={async () => {
                            navigator.clipboard
                                .writeText(window.location.href)
                                .then(() => {
                                    dispatch(
                                        asyncNotification({
                                            notification: "Copied to clipboard.",
                                            isActive: true,
                                        })
                                    );
                                    setClicked(false);
                                })
                                .catch((err) => {
                                    asyncNotification({
                                        notification: err,
                                        isActive: true,
                                        isErrNotification: true,
                                    });
                                    setClicked(false);
                                });
                        }}
                        className={`${lightMode} dropdown-content-div collections-context-menu `}
                    >
                        <img src={lightMode === "light" ? copy : copyDark} alt="Copy"/>
                        Copy to clipboard
                    </div>

                    <div
                        onClick={() => {
                            if (
                                collectionList &&
                                collectionList?.length > 0 &&
                                collectionId &&
                                id
                            )
                                deleteScreenFromCollection(collectionList, collectionId, id).catch(err => {
                                    console.error(err)
                                });
                        }}
                        className={`${lightMode} dropdown-content-div collections-context-menu `}
                    >
                        <img src={Trash} alt="Trash"/>
                        Remove screen
                    </div>
                </ContextMenuCompanyItem>
            )}
            <div className={`${lightMode}`}>
                {locked && (
                    <button
                        className={`${lightMode} purchase-plan-locked screen-flow-locked`}
                        onClick={() => setIsUpgradePlanModalOpened(true)}
                    >
                        <img
                            src={lightMode === "light" ? LockImg : LockImgDark}
                            alt={"upgrade"}
                        />
                        Upgrade to see this shot
                    </button>
                )}
                <StyledScreenImage
                    isMobile={isMobile}
                    style={
                        !locked
                            ? {
                                backgroundImage: `url(${image})`,
                            }
                            : lightMode === "light"
                                ? {
                                    backgroundColor: "#EAECF0",
                                    cursor: "default",
                                }
                                : {
                                    backgroundColor: "#1E1E1E",
                                    cursor: "default",
                                }
                    }
                    innerWidth={innerWidth}
                    areFiltersOpen={areFiltersOpen}
                    className={`${lightMode} with-border
					${
                        onFlows === false
                            ? "screen-image flows with-border"
                            : areFiltersOpen
                                ? "screen-image with-filters"
                                : "screen-image"
                    }
				`}
                />
            </div>
            <div className={`${lightMode} screen-info`}>
                {company && (
                    <img
                        src={company.imageS3URL || ""}
                        className={`${lightMode} flow-icon`}
                        alt={company.name}
                    />
                )}
                <div className={`${lightMode} screen-text design-element`}>
                    <h2>{name}</h2>
                    {company && (
                        <h3 className={`${lightMode} screen-company`}>{company.name}</h3>
                    )}
                </div>
            </div>
        </div>
    );
};
export default CompanyItem;

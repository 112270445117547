import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

const ProtectedRoute = (props: any) => {
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        const checkUserToken = () => {
            const userToken = localStorage.getItem("token");
            if (!userToken || userToken === "undefined") {
                setIsLoggedIn(false);
                sessionStorage.setItem("redirect", window.location.pathname)
                return navigate("/SignIn");
            }
            setIsLoggedIn(true);
        };
        checkUserToken();
    }, [isLoggedIn, navigate]);

    return <>{isLoggedIn ? props.children : null}</>;
};
export default ProtectedRoute;

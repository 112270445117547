import {SubmitHandler, useForm} from "react-hook-form";
import {useSearchParams} from "react-router-dom";
import {isValidEmail} from "../../utils/helpers";
import HideIcon from "../../icons/Hide.svg";
import ShowIcon from "../../icons/Show.svg";
import React, {useCallback, useState} from "react";
import {AcceptInviteToBoard, AcceptInviteToBoardRequest} from "../../utils/backend/Invites/AcceptInviteToBoard";
import {AcceptInviteToProject, AcceptInviteToProjectRequest} from "../../utils/backend/Invites/AcceptInviteToProject";

const minPasswordLength = 8;
const passwordNotLongEnoughErrorMessage = 'Password must contain at least 8 characters';
const passwordsDoNotMatchErrorMessage = 'Passwords do not match';

interface NewUserFormData {
    email: string;
    password: string;
    confirmPassword: string;
    fullName?: string;
}

type NewUserFormProps = {
    onSuccess: () => void;
    lightMode: string;
} & ({
    type: "board";
    boardId: string;
} | {
    type: "project";
    superBoardId: string;
});
export default function NewUserForm(props: NewUserFormProps) {
    const lightMode = props.lightMode;
    const [searchParams] = useSearchParams();
    const [isEmailAlreadyInUser, setIsEmailAlreadyInUser] = useState<boolean>(false);
    const [isPasswordVisible, setIsPasswordVisible] = useState({
        repeat: false,
        pwd: false,
    });

    const {
        handleSubmit,
        register,
        formState: {errors, isSubmitting},
        setError,
        getValues,
    } = useForm<NewUserFormData>({
        defaultValues: {
            email: searchParams.get("email") ?? "",
        }
    });

    const onSubmit: SubmitHandler<NewUserFormData> = useCallback(async (actualData) => {
        setIsEmailAlreadyInUser(false);
        const controller = new AbortController();
        const signal = controller.signal;
        if (props.type === "board") {
            const req: AcceptInviteToBoardRequest = {
                userEmail: actualData.email,
                boardId: props.boardId,
                password: actualData.password,
                name: actualData.fullName,
                signal,
            };
            try {
                const result = await AcceptInviteToBoard(req);
                if (result.error.length > 0) {
                    setError("root", {
                            message: "Something unexpected happened, try to reload page or contact us.",
                        }
                    );
                    console.error(result.error);
                }
                props.onSuccess();
                return;
            } catch (e) {
                setError("root", {
                        message: "Something unexpected happened, try to reload page or contact us.",
                    }
                );
                console.error(e);
            }
        } else {
            const req: AcceptInviteToProjectRequest = {
                userEmail: actualData.email,
                projectId: props.superBoardId,
                password: actualData.password,
                name: actualData.fullName,
                signal,
            };
            try {
                const result = await AcceptInviteToProject(req);
                if (result.error.length > 0) {
                    setError("root", {
                            message: "Something unexpected happened, try to reload page or contact us.",
                        }
                    );
                    console.error(result.error);
                    return;
                }
                props.onSuccess();
                return;
            } catch (e) {
                setError("root", {
                        message: "Something unexpected happened, try to reload page or contact us.",
                    }
                );
                console.error(e);
            }
        }
    }, [props, setError]);

    return <div className={`${lightMode} sign-page sign-page-container`}>
        <form className={`${lightMode} sign-container`} onSubmit={handleSubmit(onSubmit)}>
            <div className={`${lightMode} form`}>
                <div style={{marginBottom: "20px"}}>
                    <label htmlFor="email" className={`${lightMode} label`}>
                        Name
                    </label>
                    <input
                        type="text"
                        id="name"
                        className={`${lightMode} email sign-in-input`}
                        placeholder="Enter your name"
                        style={errors.fullName ? {} : {marginBottom: "20px"}}
                        {...register("fullName", {required: true})}
                    />
                    {errors.fullName && (
                        <div
                            style={{
                                color: "red",
                                fontSize: "12px",
                                marginTop: "5px",
                                marginBottom: "10px",
                                fontWeight: 500,
                            }}
                        >
                            {errors.fullName.message && errors.fullName.message?.length > 0
                                ? errors.fullName.message
                                : errors.fullName && errors.fullName.type === "required"
                                    ? "Required"
                                    : errors.fullName && errors.fullName.type === "validate"
                                        ? "Not valid name"
                                        : null}
                        </div>
                    )}
                    <label htmlFor="email" className={`${lightMode} label`}>
                        Email
                    </label>
                    <input
                        type="email"
                        id="email"
                        className={`${lightMode} email sign-in-input`}
                        placeholder="Enter your email"
                        disabled
                        style={{
                            backgroundColor: "#f2f4f7",
                        }}
                        {...register("email", {required: true, validate: isValidEmail})}
                    />
                    {errors.email && (
                        <div
                            style={{
                                color: "red",
                                fontSize: "12px",
                                marginTop: "5px",
                                marginBottom: "10px",
                                fontWeight: 500,
                            }}
                        >
                            {errors.email.message && errors.email.message?.length > 0
                                ? errors.email.message
                                : errors.email && errors.email.type === "required"
                                    ? "Required"
                                    : errors.email && errors.email.type === "validate"
                                        ? "Not valid email"
                                        : null}
                        </div>
                    )}

                    {isEmailAlreadyInUser && (
                        <h2 className={`${lightMode} error`}>
                            This email is already in use
                        </h2>
                    )}
                </div>
                <label htmlFor="password" className={`${lightMode} label pass-label`}>
                    Password
                </label>
                <div style={{marginBottom: "20px"}}>
                    <input
                        type={isPasswordVisible.pwd ? "text" : "password"}
                        id="password"
                        className={`${lightMode} password sign-in-input`}
                        placeholder="&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;"
                        {...register("password", {
                            required: true, validate: (value) => {
                                return value.length >= minPasswordLength
                            }
                        })}
                    />
                    <img
                        src={isPasswordVisible.pwd ? HideIcon : ShowIcon}
                        onClick={() =>
                            setIsPasswordVisible({
                                ...isPasswordVisible,
                                pwd: !isPasswordVisible.pwd,
                            })
                        }
                        alt={isPasswordVisible.pwd ? "HideIcon" : "ShowIcon"}
                        className={`${lightMode} show-hide-image-signin sign-up-pwd-icon`}
                    />
                    {errors.password && (
                        <div
                            style={{
                                color: "red",
                                fontSize: "12px",
                                marginTop: "5px",
                                marginBottom: "10px",
                                fontWeight: 500,
                            }}
                        >
                            {errors.password.message && errors.password.message?.length > 0
                                ? errors.password.message
                                : errors.password && errors.password.type === "required"
                                    ? "Required"
                                    : errors.password && errors.password.type === "validate"
                                        ? passwordNotLongEnoughErrorMessage
                                        : null}
                        </div>
                    )}
                </div>

                <label
                    htmlFor="confirm-password"
                    className={`${lightMode} label pass-label`}
                >
                    Password confirmation
                </label>
                <div>
                    <input
                        type={isPasswordVisible.repeat ? "text" : "password"}
                        id="confirm-password"
                        className={`${lightMode} password sign-in-input`}
                        placeholder="&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;"
                        {...register("confirmPassword", {
                            required: true, validate: (value) => {
                                return value === getValues().password
                            }
                        })}
                    />
                    <img
                        src={isPasswordVisible.repeat ? HideIcon : ShowIcon}
                        onClick={() =>
                            setIsPasswordVisible({
                                ...isPasswordVisible,
                                repeat: !isPasswordVisible.repeat,
                            })
                        }
                        alt={isPasswordVisible.repeat ? "HideIcon" : "ShowIcon"}
                        className={`${lightMode} show-hide-image-signin sign-up-pwd-icon`}
                    />
                    {errors.confirmPassword && (
                        <div
                            style={{
                                color: "red",
                                fontSize: "12px",
                                marginTop: "5px",
                                marginBottom: "10px",
                                fontWeight: 500,
                            }}
                        >
                            {errors.confirmPassword.message && errors.confirmPassword.message?.length > 0
                                ? errors.confirmPassword.message
                                : errors.confirmPassword && errors.confirmPassword.type === "required"
                                    ? "Required"
                                    : errors.confirmPassword && errors.confirmPassword.type === "validate"
                                        ? passwordsDoNotMatchErrorMessage
                                        : null}
                        </div>
                    )}
                </div>
            </div>

            <div className={`${lightMode} sign-up-buttons`}>
                <button
                    className={`${lightMode} sign-btn up`}
                    disabled={isSubmitting}
                    type={"submit"}
                >
                    Accept Invite
                </button>
            </div>
            {errors.root && errors.root.message && (
                <div
                    style={{
                        color: "red",
                        fontSize: "12px",
                        marginTop: "5px",
                        marginBottom: "10px",
                        fontWeight: 500,
                    }}
                >
                    {errors.root.message}
                </div>
            )}
        </form>
    </div>
}
import React from "react";
import {useAppSelector} from "../../store/hooks";

import "./UpgradePlanStyles.css";

import closeIcon from "../../icons/closeWhite.svg";
import closeIconDark from "../../icons/close.svg";

import {useSearchParams} from "react-router-dom";
import check from "../../icons/green-check.svg";

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const ThankYouModal: React.FC<ModalProps> = ({isOpen, onClose}) => {
    const lightMode = useAppSelector((state) => state.navigation.lightMode);
    const [searchParams] = useSearchParams();
    const isForRedemptionCode = searchParams.get("type") === "redemption";
    const quantity = searchParams.get("quantity");

    const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement>) => {
        if (event.target === event.currentTarget) {
            onClose();
        }
    };

    if (!isOpen) return null;

    const successfulPurchaseMessage = `You have successfully purchased ${
        !quantity || isNaN(parseInt(quantity!)) || parseInt(quantity!) === 1
            ? "1 account licence"
            : `${quantity} account licences`
    } of UXMaps. Thank you for your trust.`;

    const successfulRedemptionCodeActivationMessage = "You have successfully activated you redemption code. Thank you for your trust."

    return (
        <div className={`${lightMode} modal-overlay`} onClick={handleOverlayClick}>
            <div className={`${lightMode} modal upgrade-plan thank-you`}>
                <div className={`${lightMode} modal-content upgrade-plan thank-you`}>
                    <div className={`${lightMode} upgrade-plan-header thank-you`}>
                        <div className="check-icon-container">
                            <img src={check} alt={"check"}/>
                        </div>
                        <div className={`${lightMode} upgrade-plan-header-right`}>
                            <img
                                src={lightMode === "light" ? closeIconDark : closeIcon}
                                style={{width: "18px", height: "18px"}}
                                onClick={onClose}
                                alt="CloseIcon"
                            />
                        </div>
                    </div>
                    <div className={`${lightMode} upgrade-plan-content thank-you`}>
                        <p className={`${lightMode} thank-you-header`}>
                            {isForRedemptionCode ? "Redemption code successfully activated" : "Payment successful"}
                        </p>
                        <div
                            className={`${lightMode} account-limit thank-you`}
                        >
                            {isForRedemptionCode ? successfulRedemptionCodeActivationMessage : successfulPurchaseMessage}
                        </div>
                    </div>
                    <div>
                        <button className={`${lightMode} thank-you-btn`} onClick={onClose}>
                            Continue
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ThankYouModal;
